import "core-js/modules/es.function.name.js";
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-68b75c16"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["src"];
var _hoisted_2 = ["src"];
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'networkIcon',
  props: {
    size: {
      type: String,
      default: 'middle'
    },
    name: String
  },
  setup: function setup(__props) {
    var props = __props;
    var store = useStore();
    var network = computed(function () {
      return props.name || store.state._config.contractConfig.network;
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['flex items-center justify-center', __props.size])
      }, [_unref(network) ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "h-full",
        src: '/images/network/' + _unref(network).toLowerCase() + '.svg'
      }, null, 8, _hoisted_1)) : (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "h-full",
        src: '/images/common/warning.svg'
      }, null, 8, _hoisted_2))], 2);
    };
  }
};